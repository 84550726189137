export const slugUtils = {
  methods: {
    capitalizeText(str) {
      let splitStr = str.toLowerCase().split(' ');
      for (let i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      return splitStr.join(' ');
    },
    getEventTitleFromSlug(slug) {
      return this.getTitleFromSlug(slug).replace(/tulum/i,' | Tulum');
    }
    ,
    getTitleFromSlug(slug) {
      return this.capitalizeText(slug.replace(/-or-/g,' | ').replace(/-/g, ' '));
    }
  }
};
